import React from 'react'
export default function Truck () {
  return (
    <symbol id='maphubs-icon-truck' viewBox='0 0 100 51.086' preserveAspectRatio='xMidYMid meet'>
      <circle cx='13.955' cy='42.837' r='8.249' /><circle cx='76.521' cy='42.837' r='8.249' /><rect x='32.363' width='67.637' height='32.322' /><path d='M70.126,34.587H28.073V7.999L6.438,9.646c0,0-4.745,11.746-6.438,15.884v11.202v4.021c0,1.151,0.933,2.084,2.083,2.084
      h1.432c0-5.767,4.675-10.44,10.44-10.44c5.767,0,10.44,4.674,10.44,10.44h41.686C66.081,39.48,67.666,36.497,70.126,34.587z
      M19.733,23.681L3.08,25.775l4.81-12.491l11.844-0.903V23.681z'
      /><path d='M99.944,34.587H82.917c2.46,1.91,4.045,4.894,4.045,8.25h2.437h4.322l6.244-2.211L99.944,34.587z' />
    </symbol>
  )
}
